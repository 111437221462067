<template>
  <section>
    <section class="info-container">
      <section class="info-item-wrap">
        <p class="info-title m-t-10">离场人员信息</p>
        <a-row class="m-t-18">
          <a-col :span="16">
            <a-row class="info-item">
              <a-col class="key">照片</a-col>
              <a-col class="val">
                <img style="width:80px;height:100px;border-radius:4px" :src="picServer+personnelInfo.avatar" alt="">
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">姓名</a-col>
              <a-col class="val">{{personnelInfo.personnelName}}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">身份证号</a-col>
              <a-col class="val">{{personnelInfo.idCard}}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">所属单位</a-col>
              <a-col class="val">{{personnelInfo.companyName}}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">职务</a-col>
              <a-col class="val">{{personnelInfo.positionWorkName}}</a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key">登记时间</a-col>
              <a-col class="val">{{personnelInfo.inDate}}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
    </section>
    <section class="form-container">
      <section class="form-item">
        <p class="title m-t-10">操作</p>
        <a-form class="m-t-16" layout="inline" :form="form">
            <a-form-item label="退场时间">
                <a-date-picker :disabledDate="disabledEndDate" v-decorator="['outDate',{rules:[{required:true,message:'请选择退场时间'}]}]" />
            </a-form-item>
        </a-form>
        <section class="list-table-container">
            <a-table :bordered="false" size="small" class="m-t-18" :columns="columns" :data-source="listData" :pagination="false">
                 <a-row v-if="list.fileName" slot="fileName" slot-scope="text,list">
                      <preview-image :data="list.fileUrl" :size="44" />
                  </a-row>
                <a slot="action" slot-scope="text,list">

                  <upload @func="getFile" v-if="!list.fileUrl" style="display:inline-block" class="m-r-10" :node="{type:1,text:'上传'}" :accept="['.png','.jpg','.pdf','.doc','.xls']" />
                  <a-popconfirm v-else title="确定删除?" @confirm="deleteFile">删除</a-popconfirm>
                </a>
            </a-table>
        </section>
         <section class="form-item m-t-10">
               <div class="form-btn-group" style="text-align:center;padding:18px 0" >
                   <a-button @click="submit"><a-icon type="save" />保存</a-button>
                   <a-button @click="$router.back()">取消</a-button>
               </div>
            </section>
      </section>
    </section>
  </section>
</template>


<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'材料名称',dataIndex:'fileMaterialName',align:'center',key:'fileMaterialName'},
    {title:'附件',dataIndex:'fileName',align:'center',key:'fileName',scopedSlots:{customRender: "fileName"}},
    {title:'操作',align:'center',key:'action',scopedSlots:{customRender: "action"}}
]
import previewImage from '@/components/previewImage'
import upload from "@/components/upload";
import moment from 'moment'
export default {
    data(){
        return{
            columns,
            listData:[
                {key:1,fileMaterialName:'人员工资结算确认书',fileUrl:'',fileName:''},
            ],
            personnelInfo:{},
        }
    },
    components:{upload,previewImage},
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    computed:{
      personnelId(){
        return this.$route.query.id
      },
      picServer(){
            return this.$store.state.picServer
        }
    },
    created() {
      this.queryPersonnelDetails()
    },
    mounted(){
      
    },
    methods:{
        disabledEndDate(endValue) {
            const inDate = moment(this.personnelInfo.inDate);
            if (!inDate || !endValue) return false;
            return inDate.valueOf() >= endValue.valueOf();
        },
        //人员详情
        queryPersonnelDetails(){
            this.$api.queryPersonnelDetails({id:this.personnelId}).then(res =>{
                if(res.code === 200){
                    let _data = res.data || {}
                    this.personnelInfo = _data.personnelInfo
                    this.personnelInfo.companyName = _data.companyName
                  
                    this.personnelInfo.positionWorkName = _data.positionWorkName
                    this.personnelInfo.teamName = _data.teamName
                    this.personnelInfo.isLeader = _data.isLeader
                }
            })
        },
        getFile(file){
          this.listData[0].fileUrl = file.codeUrl
          this.listData[0].fileName = file.fileName
          this.listData[0].url = file.url
        },
        deleteFile(){
          this.listData[0].fileUrl = ''
          this.listData[0].fileName =''
          this.listData[0].url = ''
        },
        unBindFaceDevice(id){
          this.$api.unBindFaceDevice(id).then(() => {
          })
        },
        //提交表单
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    let params = {
                      exitFile:JSON.stringify(this.listData),
                      personnel:{
                        id:this.personnelId,
                        outDate:formData.outDate ? formData.outDate.format('YYYY-MM-DD') : null
                      }
                    }
                    this.$api.personnelExit(params).then(res =>{
                        if(res.code === 200){
                           this.unBindFaceDevice(this.personnelId)
                            this.$router.back()
                        }
                    })
                }
            })
        }
    }
}
</script>